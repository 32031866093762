import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact'
import ColumnAlternating from '../../../components/columnAlternating'
import Card from '../../../components/card'
import Cta from '../../../components/cta'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const brand = data.serverbrands.edges 
  const resources = data.resources

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {brand && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                  Try out our server design sample tool
                </h2>
                <div className="pb-5 font-w-400 text-medium">
                  Choose from the following server appliance brands
                </div>
                <MDBRow>
                  {brand.map(({ node }, index) => (
                    <MDBCol md={index < 3 ? "4" : "6"} className="py-3" key={index}>
                      <MDBCard>
                        <div style={index < 3 ? { minHeight: '520px',maxHeight: '520px' } : { minHeight: '315px',maxHeight: '315px' }} >
                          <MDBCardTitle className="text-center title-extra-small font-w-700 pt-3" style={{minHeight: "60px", maxHeight: "60px"}}>
                            <Img
                              fixed={node.frontmatter.brandlogo.childImageSharp.fixed}
                              className="m-3"
                              alt={node.frontmatter.alttext}
                            />
                          </MDBCardTitle>
                          <MDBCardBody>
                            <div
                              className="text-card-small font-w-400"
                              style={ index < 3 ? { minHeight: '175px', maxHeight: '175px' } : { minHeight: '105px', maxHeight: '105px' } }
                            >
                              {node.frontmatter.branddescription}
                            </div>
                            <hr />
                            <MDBRow>
                              {node.frontmatter.section.map( (sections, index) => (
                                <MDBCol className="px-2" key={index}>
                                  <Link
                                    to={sections.link}
                                    className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                                    style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', width: '100%' }}
                                    target="_blank"
                                  >
                                    {sections.linktext}
                                  </Link>
                                </MDBCol>
                              ))}
                            </MDBRow>
                          </MDBCardBody>
                        </div>
                      </MDBCard>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section.map((sections, index) => (
              <section className={index % 2 === 0 ? 'bg-white' : 'bg-white-grey-gradient'} key={index}>
                <MDBAnimation
                  reveal
                  type="fadeIn"
                  duration="1587ms"
                  count={1}
                >
                  <MDBContainer>
                    <div className="about-overview">
                      <ColumnAlternating
                        key={index}
                        textCol={'7'}
                        imageCol={'5'}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        description={sections.description}
                        imageUrl={sections.image.childImageSharp.fluid}
                        imageAltText={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                    </div>
                  </MDBContainer>
                </MDBAnimation>
              </section>
            ))}
          </>
        )}

        {resources && (
          <>
            {resources.frontmatter.section.map((sections, index) => (
              <section className="bg-white-grey-gradient">
                <MDBContainer>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-4 pb-4 title-xs-large title-large text-left">
                    {sections.title}
                  </h2>
                  <MDBRow>
                    {sections.feature.map((features, index) => (
                      <Card
                        key={index}
                        collg="4"
                        colmd="6"
                        height="5.5rem"
                        title={features.title}
                        subtitle={features.subtitle}
                        description={features.linktext}
                        imageUrl={features.image ? features.image.childImageSharp.fluid : null}
                        link={features.link ? features.link : features.document.publicURL}
                        titleclass="title-small"
                        descriptionClass="text-card-small"
                      />
                      ))}
                  </MDBRow>
                </MDBContainer>
              </section>
            ))}
          </>
        )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(frontmatter: { name: { eq: "server-branding" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              fluid(maxWidth: 540, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          placement
          alttext
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
    serverbrands: allMarkdownRemark( filter: {frontmatter: {brandname: {ne: null}}}, sort: {fields: frontmatter___sequence}) {
      edges {
        node {
          frontmatter {
            brandname
            branddescription
            brandlogo {
              childImageSharp {
                fixed(height: 50, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            alttext
            section {
              linktext
              link
            }
          }
        }
      }
    }
    resources: markdownRemark(frontmatter: {template: {eq: "resources"}, title: {eq: "Helpful resources"}}) {
      frontmatter {
        title
        subtitle
        section {
          title
          subtitle
          placement
          feature {
            title
            subtitle
            image {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            linktext
            link
            document {
              publicURL
            }
          }
        }
      }
    }    
  }
`